import { Close } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { AppContext } from "../App";
import { primary, success as Success, error as Error } from "../global/theme";

const Toast = () => {
  const { success, setSuccess, error, setError, info, setInfo, theme } =
    useContext(AppContext);

  const getBackgroundColor = () => {
    if (success) {
      return Success;
    } else if (error) {
      return Error;
    } else {
      return "transparent";
    }
  };

  const getTextColor = () => {
    return "white";
  };

  const closeNotif = () => {
    setSuccess(false);
    setError(false);
    setInfo("");
  };

  return (
    <motion.div
      animate={{
        opacity: success || error ? 1 : 0,
        top: success || error ? "5%" : "-10%",
      }}
      transition={{
        duration: 0.2,
      }}
      onAnimationComplete={() => {
        setTimeout(() => closeNotif(), success ? 2000 : error ? 5000 : 3000);
      }}
      style={{
        position: "absolute",
        top: "-10%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 120,
        borderRadius: 8,
        backgroundColor: getBackgroundColor(),
      }}
    >
      <div
        style={{
          minWidth: "40dvw",
          maxWidth: "80dvw",
          position: "relative",
          padding: "10px 20px",
        }}
      >
        <Close
          style={{
            position: "absolute",
            top: "50%",
            right: 5,
            transform: "translate(0%, -50%)",
            cursor: "pointer",
            color: getTextColor(),
          }}
          onClick={closeNotif}
        />
        <Typography
          //   variant="caption"
          style={{
            display: "block",
            width: "90%",
            textAlign: "left",
            fontWeight: "bold",
            color: getTextColor(),
          }}
        >
          {info}
        </Typography>
      </div>
    </motion.div>
  );
};

export default Toast;
