import { Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { black, primary, secondary, white } from "../global/theme";
import { motion } from "framer-motion";
import { Instagram, LinkedIn, X } from "@mui/icons-material";
import { AppContext } from "../App";

const Connect = ({ connectRef }) => {
  const { deviceType, connectModal, setConnectModal, setSubject } =
    useContext(AppContext);

  const handleConnect = (type) => {
    if (type) {
      console.log("type => ", type);
    }
  };
  const threadsURL = "https://www.threads.net/@antonydevon92";
  const xURL = "https://twitter.com/antonysanders_";
  const linkedInURL = "https://www.linkedin.com/in/antonysanders/";
  const cv =
    "https://firebasestorage.googleapis.com/v0/b/authenticast.appspot.com/o/2024%20RESUME.pdf?alt=media&token=8efe9c2b-accb-4b6c-b313-a17ad59f9f38";

  const openURL = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Grid
      ref={connectRef}
      item
      container
      xs={12}
      style={{
        minHeight: "60dvh",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        // overflow: "hidden",
      }}
    >
      <Grid item container xs={11} md={9} lg={8} style={{}}>
        <Typography
          style={{
            width: "100%",
            textAlign: "center",
            fontWeight: "900",
            fontFamily: `"Poppins", sans-serif`,
            color: primary,
          }}
        >
          CONNECT
        </Typography>
        <Typography
          variant="h4"
          style={{
            width: "100%",
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: `"Poppins", sans-serif`,
            marginBottom: 20,
          }}
        >
          Have any questions? I'd love to hear from you!
        </Typography>

        <Grid item container xs={12} md={4} style={{ padding: 10 }}>
          <motion.div
            initial={{ opacity: 0, x: "-100%" }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              borderRadius: 14,
              background: white,
              boxShadow: "1px 1px 15px #00000025",
              padding: "10%",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                height: 10,
                position: "absolute",
                top: "0%",
                left: "0%",
                backgroundColor: primary,
              }}
            />
            <Typography
              style={{
                width: "100%",
                textAlign: "left",
                fontWeight: "900",
                fontFamily: `"Poppins", sans-serif`,
                color: primary,
                marginTop: 20,
              }}
            >
              SOCIAL MEDIA 🌎
            </Typography>
            <Typography
              variant="h5"
              style={{
                width: "100%",
                textAlign: "left",
                fontWeight: "bold",
                fontFamily: `"Poppins", sans-serif`,
                margin: "5% 0%",
              }}
            >
              I'm active! Link with me on these networks!
            </Typography>
            <Grid
              item
              container
              xs={12}
              style={{
                padding: "5%",
                justifyContent: "space-evenly",
                alignItems: "flex-end",
                marginTop: 40,
              }}
            >
              <motion.div
                onClick={() => openURL(linkedInURL)}
                whileHover={{
                  scale: 1.2,
                  backgroundColor: `${primary}`,
                  color: `${white}`,
                }}
                style={{
                  cursor: "pointer",
                  padding: "10px 12px",
                  borderRadius: 50,
                  boxShadow: "1px 1px 15px #00000025",
                }}
              >
                <LinkedIn />
              </motion.div>
              <motion.div
                onClick={() => openURL(xURL)}
                whileHover={{
                  scale: 1.2,
                  backgroundColor: `${primary}`,
                  color: `${white}`,
                }}
                style={{
                  cursor: "pointer",
                  padding: "10px 12px",
                  borderRadius: 50,
                  boxShadow: "1px 1px 15px #00000025",
                }}
              >
                <X />
              </motion.div>
              <motion.div
                onClick={() => openURL(threadsURL)}
                whileHover={{
                  scale: 1.2,
                  backgroundColor: `${primary}`,
                  color: `${white}`,
                }}
                style={{
                  cursor: "pointer",
                  padding: "10px 12px",
                  borderRadius: 50,
                  boxShadow: "1px 1px 15px #00000025",
                }}
              >
                <Instagram />
              </motion.div>
            </Grid>
          </motion.div>
        </Grid>

        <Grid item container xs={12} md={4} style={{ padding: 10 }}>
          <motion.div
            initial={{ opacity: 0, x: "-100%" }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.25, duration: 0.5 }}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              borderRadius: 14,
              background: white,
              boxShadow: "1px 1px 15px #00000025",
              padding: "10%",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                height: 10,
                position: "absolute",
                top: "0%",
                left: "0%",
                backgroundColor: primary,
              }}
            />
            <Typography
              style={{
                width: "100%",
                textAlign: "left",
                fontWeight: "900",
                fontFamily: `"Poppins", sans-serif`,
                color: primary,
                marginTop: 20,
              }}
            >
              GENERAL INQUIRY 🗳️
            </Typography>
            {/* 🗳️🔗💼🌎 */}
            <Typography
              variant="h5"
              style={{
                width: "100%",
                textAlign: "left",
                fontWeight: "bold",
                fontFamily: `"Poppins", sans-serif`,
                margin: "5% 0%",
              }}
            >
              Have an App Idea? Looking for Mentorship? Get in touch.
            </Typography>

            <Grid
              item
              container
              xs={12}
              style={{
                padding: "5%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <motion.button
                onClick={() => setConnectModal(!connectModal)}
                whileHover={{
                  backgroundColor: secondary,
                  color: black,
                  scale: 0.95,
                }}
                style={{
                  borderRadius: 40,
                  height: 40,
                  backgroundColor: primary,
                  color: white,
                  width: "100%",
                  padding: "0px 20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  cursor: "pointer",
                  fontWeight: "bold",
                  fontFamily: `"Poppins", sans-serif`,
                }}
              >
                CONTACT
              </motion.button>
            </Grid>
          </motion.div>
        </Grid>

        <Grid item container xs={12} md={4} style={{ padding: 10 }}>
          <motion.div
            initial={{ opacity: 0, x: "-100%" }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              borderRadius: 14,
              background: white,
              boxShadow: "1px 1px 15px #00000025",
              padding: "10%",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                height: 10,
                position: "absolute",
                top: "0%",
                left: "0%",
                backgroundColor: primary,
              }}
            />
            <Typography
              style={{
                width: "100%",
                textAlign: "left",
                fontWeight: "900",
                fontFamily: `"Poppins", sans-serif`,
                color: primary,
                marginTop: 20,
              }}
            >
              HIRE ME 💼
            </Typography>
            {/* 🗳️🔗💼🌎 */}
            <Typography
              variant="h5"
              style={{
                width: "100%",
                textAlign: "left",
                fontWeight: "bold",
                fontFamily: `"Poppins", sans-serif`,
                margin: "5% 0%",
              }}
            >
              Have a job you think I'd be perfect for? Let's talk.
            </Typography>
            <Typography
              style={{
                width: "100%",
                textAlign: "left",
                fontFamily: `"Poppins", sans-serif`,
              }}
            >
              And while you're at it, grab a copy of my CV!
            </Typography>

            <Grid
              item
              container
              xs={12}
              style={{
                padding: "5%",
                justifyContent: "space-evenly",
                alignItems: "flex-end",
              }}
            >
              <motion.button
                onClick={() => {
                  setConnectModal(!connectModal);
                  setSubject("Job Opportunities");
                }}
                whileHover={{
                  backgroundColor: secondary,
                  color: black,
                  scale: 0.95,
                }}
                style={{
                  borderRadius: 40,
                  height: 40,
                  backgroundColor: primary,
                  color: white,
                  width: "40%",
                  padding: "0px 20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  cursor: "pointer",
                  fontWeight: "bold",
                  fontFamily: `"Poppins", sans-serif`,
                }}
              >
                INQUIRE
              </motion.button>
              <motion.button
                onClick={() => openURL(cv)}
                whileHover={{
                  border: `2px solid ${secondary}`,
                  color: black,
                  scale: 0.95,
                }}
                style={{
                  borderRadius: 40,
                  height: 40,
                  backgroundColor: white,
                  color: primary,
                  width: "55%",
                  padding: "0px 20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: `2px solid ${primary}`,
                  cursor: "pointer",
                  fontWeight: "bold",
                  fontFamily: `"Poppins", sans-serif`,
                }}
              >
                DOWNLOAD CV
              </motion.button>
            </Grid>
          </motion.div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Connect;
