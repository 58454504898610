import React, { useContext, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Grid,
  Button,
  Typography,
  Modal,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  CircularProgress,
} from "@mui/material";
import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Send,
} from "@mui/icons-material";
import {
  black,
  primary,
  secondary,
  white,
  success,
  error,
} from "../../global/theme";
import { inquiryTypes, budgetSizes, amtOfCustomers } from "../../Utils";
import { AppContext } from "../../App";
import { functions } from "../../config/firebaseConfig";

const ContactModal = ({ showModal, setShowModal }) => {
  const {
    deviceType,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    subject,
    setSubject,
    message,
    setMessage,
    amountOfCustomers,
    setAmountOfCustomers,
    budget,
    setBudget,
    handleCloseContactModal,
    setError,
    setSuccess,
    setInfo,
  } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const handleChange = (event, type) => {
    switch (type) {
      case "name":
        setName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;
      case "subject":
        setSubject(event.target.value);
        break;
      case "message":
        setMessage(event.target.value);
        break;
      case "budget":
        setBudget(event.target.value);
        break;
      case "amountOfCustomers":
        setAmountOfCustomers(event.target.value);
        break;

      default:
        break;
    }
  };

  const handleClearForm = () => {
    setName("");
    setPhone("");
    setEmail("");
    setSubject("");
    setMessage("");
    setBudget("");
    setAmountOfCustomers("");
    setLoading(false);
    handleCloseContactModal();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const contactMe = functions.httpsCallable("contactMe");
      const response = await contactMe({
        name,
        email,
        phone,
        subject,
        message,
        budget,
        amountOfCustomers,
      });

      if (response.data.status === "success") {
        setSuccess(true);
        setInfo("Message sent successfully!");
        handleClearForm();
      } else {
        setError(true);
        setLoading(false);
        setInfo("Error sending message. Please try again.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <Modal open={showModal} onClose={handleCloseContactModal}>
      <Grid
        item
        container
        xs={11}
        md={6}
        lg={5}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: white,
          borderRadius: 14,
          padding: 20,
          outline: "none",
        }}
      >
        <Grid
          item
          container
          xs={12}
          style={{
            maxHeight: "70dvh",
            overflowY: deviceType === "mobile" ? "scroll" : "hidden",
          }}
        >
          <Typography
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: "900",
              fontFamily: `"Poppins", sans-serif`,
              color: primary,
            }}
          >
            CONTACT
          </Typography>
          <Typography
            variant="h4"
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
              fontFamily: `"Poppins", sans-serif`,
              marginBottom: 20,
            }}
          >
            Fill out the form below.
          </Typography>
          <TextField
            fullWidth
            required
            id="name"
            label="Name"
            variant="outlined"
            value={name}
            style={{ marginBottom: 10 }}
            onChange={(e) => handleChange(e, "name")}
          />
          <TextField
            fullWidth
            required
            id="email"
            label="Email"
            type="email"
            variant="outlined"
            value={email}
            style={{ marginBottom: 10 }}
            onChange={(e) => handleChange(e, "email")}
          />
          <TextField
            fullWidth
            id="phone"
            label="Phone"
            variant="outlined"
            value={phone}
            style={{ marginBottom: 10 }}
            onChange={(e) => handleChange(e, "phone")}
          />

          <FormControl fullWidth>
            <InputLabel id="label1">Inquiry Type</InputLabel>
            <Select
              labelId="label1"
              id="select1"
              value={subject}
              label="Inquiry Type"
              onChange={(e) => handleChange(e, "subject")}
            >
              {inquiryTypes.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {subject === "Web / App Development" && (
            <>
              <FormControl fullWidth style={{ marginTop: 10 }}>
                <InputLabel id="label2">Budget</InputLabel>
                <Select
                  labelId="label2"
                  id="select2"
                  value={budget}
                  label="Budget"
                  onChange={(e) => handleChange(e, "budget")}
                >
                  {budgetSizes.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginTop: 10 }}>
                <InputLabel id="label3">
                  Amount of Customers (monthly)
                </InputLabel>
                <Select
                  labelId="label3"
                  id="select3"
                  value={amountOfCustomers}
                  label="Amount of Customers (monthly)"
                  onChange={(e) => handleChange(e, "amountOfCustomers")}
                >
                  {amtOfCustomers.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <TextField
            fullWidth
            required
            multiline
            minRows={4}
            maxRows={5}
            id="message"
            label="Message"
            variant="outlined"
            value={message}
            style={{ margin: "10px 0px" }}
            onChange={(e) => handleChange(e, "message")}
          />

          <motion.button
            onClick={handleSubmit}
            whileHover={{
              backgroundColor: secondary,
              color: black,
              scale: 0.95,
            }}
            style={{
              borderRadius: 40,
              height: 40,
              backgroundColor: primary,
              color: white,
              width: "100%",
              padding: "0px 20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "none",
              cursor: "pointer",
              fontWeight: "bold",
              fontFamily: `"Poppins", sans-serif`,
            }}
          >
            {loading ? (
              <CircularProgress
                style={{ width: 24, height: 24, color: "#00000050" }}
              />
            ) : (
              <>
                {" "}
                SEND <Send />
              </>
            )}
          </motion.button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ContactModal;
