import react from "../src/assets/react.png";
import firebase from "../src/assets/firebase.png";
import gcp from "../src/assets/gcp.png";
import mui from "../src/assets/mui.png";
import javascript from "../src/assets/javascript.png";
import html from "../src/assets/html.png";
import css from "../src/assets/css.png";
import python from "../src/assets/python.png";
import stripe from "../src/assets/stripe.png";
import openAi from "../src/assets/open-ai.png";

import fray1 from "../src/assets/portfolio/fray.png";
import bzzzrrrt1 from "../src/assets/portfolio/bzzzrrrt.png";
import vizz1 from "../src/assets/portfolio/vizz.png";
import bizzybook1 from "../src/assets/portfolio/bizzybook.png";

export const inquiryTypes = [
  "Job Opportunities",
  "Web / App Development",
  "Technical Questions",
  "Speaking Engagements",
  "Mentorship Requests",
  "Feedback on Portfolio",
  "Networking",
  "Blog and Content",
  "Consultations",
];

export const budgetSizes = [
  "Less than $500",
  "$500 - $1,000",
  "$1,000 - $5,000",
  "$5,000 - $10,000",
  "$10,000 - $50,000",
];

export const amtOfCustomers = [
  "Less than 10",
  "10 - 50",
  "50 - 100",
  "100 - 500",
  "500 - 1,000",
  "1,000 - 5,000",
  "5,000+",
];

export const bio =
  "Over the past five years, I've honed my skills in leveraging cutting-edge technologies like Python, JavaScript, React, and Node.js to craft efficient, user-centric applications. My tech journey began in 2017 while still a struggling musician and security concierge at MailChimp. My passion for music led me to develop HitStreamr, a prototype music video streaming app. This project caught the attention of MailChimp’s Co-Founder/CEO, Ben Chesnut, who became my mentor. 8 months later, I was offer $500k for the aquisition of my app, and that ignited my love for coding. After a transformative 10-month stint at Flatiron School, I graduated and was immediately hired—the first in my cohort to secure a position. Since then, I have enriched my career with roles at companies like Athlete Analytics and C.U.T.I. App, where I spearheaded projects that greatly enhanced user experiences and operational efficiencies. As a professional artist and designer, I excel at blending creative vision with technical expertise to deliver superior digital solutions. Driven by a commitment to innovation and continuous improvement, my work continuously strives to meld aesthetics with functionality in every endeavor.";

export const projects = [
  {
    name: "Arm Swing Tracker",
    subTitle: "A Tool to Measure Athletes' Arm Loads",
    details: `The Arm Swing Tracker is a Windows desktop that app uses BlueTooth technology to record athletic movement over time. This platform uses unique algorithms to measure and display the data collected from those devices in a seamless fashion, helping coaches understand and see how much force the athlete uses when swinging thier arms.`,
    images: ["", ""],
    techStack: [python, javascript, react, html, css, gcp, firebase, mui],
    apis: [],
    type: ["Desktop", "Web", "Mobile"],
    github: "",
    link: "",
  },
  {
    name: "BZZZRRRT",
    subTitle: "AI Powered Social Media Management Suite",
    details: `The #1 Threads content creator. Create engainging content. Monitor performance.Easy Peasy.
	On BZZZRRRT.com you get access to data driven content and AI tools.`,
    images: [bzzzrrrt1, ""],
    techStack: [react, javascript, html, css, gcp, firebase, mui],
    apis: [stripe, openAi],
    type: ["Web", "Mobile"],
    github: "",
    link: "https://bzzzrrrt.com",
  },
  {
    name: "Fray",
    subTitle: "An Easy-to-Play, Social Elimnaition Card Game",
    details: `Fray is an easy-to-play, social elimination, role-playing card game where players must do all they can (including work together) to eliminate the Atrocities (bosses) & all other players in order to declare victory.
	You can play with 2 to up to 8 Players online in this epic free for all battle!`,
    images: [fray1, ""],
    techStack: [react, javascript, html, css, gcp, firebase, mui],
    apis: [stripe],
    type: ["Web", "Mobile"],
    github: "",
    link: "https://fray-b545a.firebaseapp.com",
  },
  {
    name: "BizzyBook",
    subTitle: "Powerful On-Demand Appointment Booking Platform",
    details: `BizzyBook is a light-weight, free scheduling platform and makes it easy to book appointments within seconds. No phone tag. Book anytime, from anywhere, 24/7.`,
    images: [bizzybook1, ""],
    techStack: [react, javascript, html, css, gcp, firebase, mui],
    apis: [stripe],
    type: ["Web", "Mobile"],
    github: "",
    link: "https://bizzybook.app",
  },

  {
    name: "Vizz",
    subTitle: "Data Visualization Simplified",
    details: `Vizz is your all-in-one solution for transforming raw data into actionable insights.
	With Vizz, you can upload raw data in various formats, select and compare key metrics, and create stunning visualizations in just a few clicks.`,
    images: [vizz1, ""],
    techStack: [react, javascript, html, css, gcp, firebase, mui],
    apis: [stripe, openAi],
    type: ["Web", "Mobile"],
    github: "",
    link: "https://vizz-now.com",
  },
];
