import React, { useState, useEffect, createContext } from "react";
import "./App.css";
import Main from "./views/Main";
import SplashScreen from "./views/SplashScreen";
import AboutModal from "./components/modals/AboutModal";
import Toast from "./components/Toast";

export const AppContext = createContext();

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(true);
  const [info, setInfo] = useState("Welcome!");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [budget, setBudget] = useState("");
  const [amountOfCustomers, setAmountOfCustomers] = useState("");
  const [message, setMessage] = useState("");
  const [fullBio, setFullBio] = useState(false);
  const [deviceType, setDeviceType] = useState(null);
  const [connectModal, setConnectModal] = useState(false);

  const handleCloseContactModal = () => {
    setName("");
    setPhone("");
    setEmail("");
    setSubject("");
    setMessage("");
    setBudget("");
    setAmountOfCustomers("");
    setConnectModal(false);
  };

  const values = {
    loading,
    success,
    error,
    info,
    setError,
    setSuccess,
    setInfo,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    subject,
    setSubject,
    fullBio,
    setFullBio,
    deviceType,
    setDeviceType,
    connectModal,
    setConnectModal,
    message,
    setMessage,
    handleCloseContactModal,
    budget,
    setBudget,
    amountOfCustomers,
    setAmountOfCustomers,
  };

  useEffect(() => {
    if (!!loading) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
    if (!deviceType) {
      setDeviceType(window.innerWidth < 768 ? "mobile" : "desktop");
    }
  }, []);

  return (
    <AppContext.Provider value={values}>
      <div
        className="App"
        style={{
          height: "100dvh",
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Toast />
        {loading ? <SplashScreen /> : <Main />}
      </div>
    </AppContext.Provider>
  );
}

export default App;
