import { Grid, Typography, Button } from "@mui/material";
import React, { useState, useContext } from "react";
import { bio } from "../Utils";
import { black, primary, secondary, white } from "../global/theme";
import profile from "../assets/profile.jpeg";
import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import { AppContext } from "../App";
import AboutModal from "../components/modals/AboutModal";

const About = ({ aboutRef }) => {
  const { fullBio, setFullBio } = useContext(AppContext);

  return (
    <Grid
      ref={aboutRef}
      item
      container
      xs={12}
      style={{
        minHeight: "60dvh",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <AboutModal showModal={fullBio} setShowModal={setFullBio} />
      <Grid item container xs={11} md={9} lg={8} style={{}}>
        <Grid
          item
          container
          xs={12}
          md={5}
          lg={6}
          sx={{ alignItems: { xs: "flex-end", md: "center" } }}
          style={{ padding: "5%" }}
        >
          <motion.img
            initial={{ width: "1%", opacity: 0 }}
            animate={{ width: "100%", opacity: 1 }}
            alt=""
            src={profile}
            style={{
              width: "100%",
              height: "65%",
              objectFit: "cover",
              objectPosition: "0% 20%",
              borderRadius: 20,
            }}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={7}
          lg={6}
          style={{ padding: "5%", alignItems: "center" }}
        >
          <motion.div
            animate={{ opacity: fullBio ? 0.15 : 1 }}
            style={{ width: "100%" }}
          >
            <Typography
              style={{
                width: "100%",
                textAlign: "left",
                fontWeight: "900",
                fontFamily: `"Poppins", sans-serif`,
                color: primary,
              }}
            >
              ABOUT ME
            </Typography>
            <Typography
              variant="h4"
              style={{
                width: "100%",
                textAlign: "left",
                fontWeight: "bold",
                fontFamily: `"Poppins", sans-serif`,
                marginBottom: 20,
              }}
            >
              A creative software engineer based in Atlanta, GA 📍
            </Typography>
            <Typography
              style={{
                width: "100%",
                fontFamily: `"Poppins", sans-serif`,
                textAlign: "left",
              }}
            >
              {bio.slice(0, 570)}
            </Typography>

            <motion.button
              onClick={() => setFullBio(!fullBio)}
              whileHover={{
                backgroundColor: secondary,
                color: black,
                scale: 0.95,
              }}
              animate={{ x: fullBio ? "1000%" : 0 }}
              transition={{ delay: fullBio ? 0 : 0.5 }}
              style={{
                borderRadius: 40,
                height: 40,
                backgroundColor: primary,
                color: white,
                padding: "0px 20px",
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                border: "none",
                cursor: "pointer",
                fontWeight: "bold",
                fontFamily: `"Poppins", sans-serif`,
              }}
            >
              READ MORE
              <KeyboardArrowRight />
            </motion.button>
          </motion.div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default About;
