import React, { useContext, useState, useEffect, useRef } from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import { Grid, Button } from "@mui/material";
import AppBar from "../components/AppBar";
import { projects } from "../Utils";
import { primary, white } from "../global/theme";
import Portfolio from "../components/Portfolio";
import { AppContext } from "../App";
import Connect from "../components/Connect";
import { KeyboardArrowUp } from "@mui/icons-material";
import ContactModal from "../components/modals/ContactModal";
import Toast from "../components/Toast";

const Main = () => {
  const { fullBio, setFullBio, connectModal, setConnectModal } =
    useContext(AppContext);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const aboutRef = useRef(null);
  const portfolioRef = useRef(null);
  const connectRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > document.querySelector(".hero").offsetHeight) {
        setShowScrollTopButton(true);
      } else {
        setShowScrollTopButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <Grid
      item
      container
      xs={12}
      style={{ position: "relative", justifyContent: "center" }}
    >
      <Toast />
      <AppBar
        scrollToSection={(section) => {
          if (section === "about" && aboutRef.current) {
            aboutRef.current.scrollIntoView({ behavior: "smooth" });
          } else if (section === "portfolio" && portfolioRef.current) {
            portfolioRef.current.scrollIntoView({ behavior: "smooth" });
          } else if (section === "connect" && connectRef.current) {
            connectRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }}
      />
      <Hero scrollToBottom={scrollToBottom} />
      <About aboutRef={aboutRef} />
      <Portfolio portfolioRef={portfolioRef} />
      <Connect connectRef={connectRef} />
      {showScrollTopButton && (
        <Button
          onClick={scrollToTop}
          style={{
            position: "fixed",
            height: 60,
            bottom: 16,
            right: 16,
            border: `2px solid ${primary}`,
            borderRadius: 14,
            color: primary,
            backgroundColor: white,
          }}
        >
          <KeyboardArrowUp />
        </Button>
      )}
      <ContactModal showModal={connectModal} setShowModal={setConnectModal} />
    </Grid>
  );
};

export default Main;
