import React, { useContext, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Grid, Button, Typography } from "@mui/material";
import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { black, primary, secondary, white } from "../../global/theme";
import { bio } from "../../Utils";
import { AppContext } from "../../App";

const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};
const chest = {
  hidden: {
    x: -100,
    // opacity: 0,
  },
  visible: {
    x: 0,
    // opacity: 1,
    // transition: { delay: 0.5 },
  },
};

const infoModal = {
  hidden: {
    // opacity: 0,
  },
  visible: {
    // opacity: 1,
    // transition: { delay: 0.3 },
  },
};

const AboutModal = ({ showModal, setShowModal, AboutModal, setAboutModal }) => {
  const { deviceType } = useContext(AppContext);

  return (
    <motion.div
      animate={{ x: showModal ? 0 : "100%", y: "-50%" }}
      transition={{ delay: showModal ? 0 : 0.3 }}
      style={{
        maxWidth: deviceType === "mobile" ? "95%" : "45%",
        height: "97%",
        borderRadius: "14px 0px 0px 14px",
        boxShadow: "5px -5px 15px #00000065",
        background: white,
        position: "absolute",
        top: "50%",
        right: "-1%",
        transform: "translateY(-50%) translateX(0%)",
        zIndex: 10,
        overflow: "hidden",
      }}
    >
      <Grid
        item
        container
        xs={12}
        style={{
          padding: "5%",
          justifyContent: "center",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <Typography
          variant="h4"
          style={{
            color: primary,
            fontFamily: `"Poppins", sans-serif`,
            fontWeight: "bold",
            width: "100%",
          }}
        >
          MORE ABOUT ME
        </Typography>
        <Typography
          style={{
            color: black,
            fontFamily: `"Poppins", sans-serif`,
            width: "100%",
          }}
        >
          {bio}
        </Typography>
        <div
          style={{
            width: "100%",
            height: 150,
            position: "absolute",
            bottom: "0%",
            left: "0%",
            background: "rgb(255,255,255)",
            background:
              "linear-gradient(0deg, rgba(255,255,255,1) 15%, rgba(255,255,255,0) 100%)",
          }}
        ></div>
        <div style={{ width: "100%", height: 60 }}></div>

        <motion.button
          whileHover={{
            backgroundColor: secondary,
            color: black,
            scale: 0.95,
          }}
          onClick={() => setShowModal(!showModal)}
          style={{
            borderRadius: 40,
            height: 40,
            background: primary,
            color: white,
            fontWeight: "bold",
            fontFamily: `"Poppins", sans-serif`,
            padding: "0px 20px",
            display: "flex",
            alignItems: "center",
            border: "none",
            cursor: "pointer",
            marginTop: 20,
            position: "absolute",
            bottom: "3%",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          CLOSE <KeyboardArrowRight />{" "}
        </motion.button>
      </Grid>
    </motion.div>
  );
};

export default AboutModal;
