import React, { useState, useContext } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { black, primary, secondary, white } from "../global/theme";
import { projects } from "../Utils";
import logo from "../assets/AAK Logo/2.png";
import logo2 from "../assets/AAK Logo/3.png";
import { motion } from "framer-motion";
import { ArrowOutward, GitHub, KeyboardArrowDown } from "@mui/icons-material";
import { AppContext } from "../App";

const Portfolio = ({ portfolioRef }) => {
  const { deviceType } = useContext(AppContext);
  const [projectsInView, setProjectsInView] = useState(projects.slice(0, 2));
  const [canViewMore, setCanViewMore] = useState(true);

  const handleViewMore = () => {
    if (projectsInView.length < projects.length) {
      let newArr = [
        ...projectsInView,
        ...projects.slice(projectsInView.length, projectsInView.length + 2),
      ];
      if (newArr.length >= projects.length) {
        setCanViewMore(false);
      }
      setProjectsInView(newArr);
    }
  };

  const openURL = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Grid
      ref={portfolioRef}
      item
      container
      xs={12}
      md={9}
      lg={8}
      style={{ padding: "5%", justifyContent: "center" }}
    >
      <Typography
        style={{
          width: "100%",
          textAlign: "left",
          fontWeight: "900",
          fontFamily: `"Poppins", sans-serif`,
          color: primary,
        }}
      >
        PORTFOLIO
      </Typography>
      <Typography
        variant="h4"
        style={{
          width: "100%",
          textAlign: "left",
          fontWeight: "bold",
          fontFamily: `"Poppins", sans-serif`,
          marginBottom: 20,
        }}
      >
        Unique projects, with current user bases 👑
      </Typography>
      {projectsInView.map((project, idx) => {
        const {
          name,
          subTitle,
          details,
          link,
          images,
          github,
          apis,
          techStack,
          type,
        } = project;
        const isEven = idx % 2 === 0;
        return (
          <Grid
            item
            container
            xs={12}
            style={{
              background: white,
              minHeight: "40dvh",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <Grid
              item
              container
              xs={12}
              md={5}
              lg={6}
              style={{
                padding: "2%",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
              sx={{ display: { xs: "flex", md: isEven ? "flex" : "none" } }}
            >
              <motion.img
                initial={{ opacity: 0, x: "-100%" }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.25, duration: 0.5 }}
                src={images[0] !== "" ? images[0] : logo}
                alt=""
                style={{ width: "100%", borderRadius: 20 }}
              />
            </Grid>
            <Grid item container xs={12} md={7} lg={6}>
              <motion.div
                initial={{ opacity: 0, y: "50%" }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.25, duration: 0.5 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "5%",
                  alignItems: "center",
                  height: "fit-content",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    fontFamily: `"Poppins", sans-serif`,
                    color: !isEven ? primary : secondary,
                  }}
                >
                  {name}
                </Typography>
                <Typography
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    fontFamily: `"Poppins", sans-serif`,
                    marginBottom: 20,
                  }}
                >
                  {subTitle}
                </Typography>
                <Typography
                  style={{
                    width: "100%",
                    fontFamily: `"Poppins", sans-serif`,
                    marginBottom: 20,
                  }}
                >
                  {details}
                </Typography>
                <Grid
                  item
                  container
                  xs={12}
                  md={6}
                  sx={{
                    justifyContent: { xs: "center", md: "flex-start" },
                    marginTop: { xs: 1, md: 0 },
                  }}
                  style={{ alignItems: "center" }}
                >
                  <Typography
                    variant="caption"
                    style={{
                      fontWeight: "bold",
                      fontFamily: `"Poppins", sans-serif`,
                      marginRight: 5,
                    }}
                  >
                    Stack:
                  </Typography>
                  {techStack.map((image, idx) => {
                    return (
                      <img
                        key={idx}
                        src={image}
                        alt=""
                        style={{ width: 20, height: 20, marginRight: 5 }}
                      />
                    );
                  })}
                </Grid>

                {apis.length > 0 && (
                  <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                      justifyContent: { xs: "center", md: "flex-start" },
                      marginTop: { xs: 1, md: 0 },
                    }}
                    style={{ alignItems: "center" }}
                  >
                    <Typography
                      variant="caption"
                      style={{
                        fontWeight: "bold",
                        fontFamily: `"Poppins", sans-serif`,
                        marginRight: 5,
                      }}
                    >
                      API(s):
                    </Typography>
                    {apis.map((api, idx) => {
                      return (
                        <img
                          key={idx}
                          variant="caption"
                          src={api}
                          alt=""
                          style={{ height: 20, width: 20, marginRight: 5 }}
                        />
                      );
                    })}
                  </Grid>
                )}

                <Grid
                  item
                  container
                  xs={12}
                  md={6}
                  sx={{
                    justifyContent: {
                      xs: "center",
                      md: apis.length > 0 ? "flex-start" : "flex-end",
                    },
                    marginTop: { xs: 1, md: apis.length > 0 ? 1 : 0 },
                  }}
                  style={{ alignItems: "center" }}
                >
                  <Typography
                    variant="caption"
                    style={{
                      fontWeight: "bold",
                      fontFamily: `"Poppins", sans-serif`,
                      marginRight: 5,
                    }}
                  >
                    Platform(s):
                  </Typography>
                  {type.map((p, idx) => {
                    return (
                      <Typography
                        key={idx}
                        variant="caption"
                        alt=""
                        style={{ marginRight: 5 }}
                      >
                        {p}
                        {idx !== type.length - 1 && ", "}
                      </Typography>
                    );
                  })}
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={"space-evenly"}
                  style={{ marginTop: 20 }}
                >
                  <motion.button
                    whileHover={{
                      border: `2px solid ${primary}`,
                      color: primary,
                      scale: 0.95,
                    }}
                    disabled={github === ""}
                    style={{
                      opacity: github === "" ? 0.5 : 1,
                      borderRadius: 40,
                      height: 40,
                      backgroundColor: white,
                      color: black,
                      width: "40%",
                      padding: "0px 20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `2px solid ${secondary}`,
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontFamily: `"Poppins", sans-serif`,
                    }}
                  >
                    <GitHub /> GitHub
                  </motion.button>
                  <motion.button
                    disabled={link === ""}
                    onClick={() => {
                      openURL(link);
                    }}
                    whileHover={{
                      backgroundColor: secondary,
                      color: black,
                      scale: 0.95,
                    }}
                    style={{
                      opacity: link === "" ? 0.5 : 1,
                      borderRadius: 40,
                      height: 40,
                      backgroundColor: primary,
                      color: white,
                      width: "40%",
                      padding: "0px 20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "none",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontFamily: `"Poppins", sans-serif`,
                    }}
                  >
                    {" "}
                    Visit <ArrowOutward />
                  </motion.button>
                </Grid>
              </motion.div>
            </Grid>

            {!isEven && (
              <Grid
                item
                xs={12}
                md={5}
                lg={6}
                style={{ padding: "2%" }}
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                <motion.img
                  initial={{ opacity: 0, x: "100%" }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.25, duration: 0.5 }}
                  src={images[0] !== "" ? images[0] : logo}
                  alt=""
                  style={{ width: "100%", borderRadius: 20 }}
                />
              </Grid>
            )}
          </Grid>
        );
      })}

      {projectsInView.length < projects.length && (
        <motion.button
          onClick={handleViewMore}
          whileHover={{
            backgroundColor: primary,
            color: white,
            scale: 0.95,
          }}
          style={{
            borderRadius: 40,
            height: 40,
            backgroundColor: secondary,
            color: black,
            width: "50%",
            padding: "0px 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            cursor: "pointer",
            fontWeight: "bold",
            fontFamily: `"Poppins", sans-serif`,
            marginTop: 30,
          }}
        >
          VIEW MORE PROJECTS <KeyboardArrowDown />
        </motion.button>
      )}
    </Grid>
  );
};

export default Portfolio;
