import React, { useContext } from "react";
import photo from "../assets/bb-social-banner.png";
import stack from "../assets/stack.png";
import { Grid, Typography } from "@mui/material";
import { black, primary, secondary, white } from "../global/theme";
import { motion } from "framer-motion";
import krown from "../assets/AAK Logo/K.png";
import aa from "../assets/AAK Logo/AA.png";
import { AppContext } from "../App";

const Hero = ({ scrollToBottom }) => {
  const { deviceType } = useContext(AppContext);
  return (
    <Grid
      className="hero"
      item
      container
      xs={12}
      style={{
        height: "100dvh",
        background: `rgb(255,255,255)`,
        background: `linear-gradient(0deg, rgba(255,255,255,1) 85%, rgba(210,210,210,1) 100%)`,
        position: "relative",
        overflow: "hidden",
        // paddingTop: 70,
        // alignContent: "center",
      }}
      sx={{
        backgroundSize: { xs: "100%", md: "40%" },
        alignItems: { md: "center", xs: "flex-start" },
        paddingLeft: { md: "10%" },
        paddingTop: { xs: 12, md: 7 },
      }}
    >
      <motion.img
        initial={{ opacity: 0, y: -150 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
        src={krown}
        style={{
          height: "5%",
          position: "absolute",
          top: deviceType === "mobile" ? "50%" : "26%",
          right: deviceType === "mobile" ? "26%" : "14%",
          rotate: "35deg",
          //   transform: "translate(-50%, -50%)",
          zIndex: 3,
        }}
      />
      <Grid
        item
        sx={{ height: { xs: "46%", md: "70%" } }}
        style={{ position: "absolute", bottom: "0%", right: "0%", zIndex: 2 }}
      >
        <motion.img
          initial={{ opacity: 0, y: 150 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          src={photo}
          alt=""
          style={{
            height: "100%",
          }}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={6}
        style={{
          height: "fit-content",
          alignContent: "center",
          padding: "0% 8%",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: { md: "left", sx: "center" },
          }}
          style={{
            fontWeight: "bold",
            width: "100%",
            fontFamily: `"Poppins", sans-serif`,
            color: primary,
          }}
        >
          Antony The
        </Typography>
        <Typography
          variant="h2"
          sx={{
            textAlign: { md: "left", sx: "center" },
          }}
          style={{
            fontWeight: "bold",
            width: "100%",
            fontFamily: `"Poppins", sans-serif`,
            color: primary,
            marginTop: "-10px",
          }}
        >
          App King
        </Typography>

        {/* <Typography
          variant="h5"
          sx={{
            textAlign: { md: "left", sx: "center" },
          }}
          style={{
            fontWeight: "bold",
            width: "100%",
            fontFamily: `"Poppins", sans-serif`,
          }}
        >
          Builder Of Things 🚀
        </Typography> */}

        <Typography
          sx={{
            textAlign: { md: "left", sx: "center" },
          }}
          style={{
            width: "100%",
            marginTop: 20,
            fontFamily: `"Poppins", sans-serif`,
          }}
        >
          Artist. Entrepreneur. Engineer. Innovator. 🚀
        </Typography>

        <Grid
          item
          container
          xs={12}
          style={{
            marginTop: "5%",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              fontFamily: `"Poppins", sans-serif`,
            }}
          >
            Tech Stack
          </Typography>
          <Typography
            style={{
              fontWeight: "bold",
              margin: "0% 5%",
              fontFamily: `"Poppins", sans-serif`,
            }}
          >
            |
          </Typography>
          <img src={stack} alt="" style={{ width: "60%" }} />
        </Grid>

        <Grid
          item
          container
          xs={12}
          sx={{
            justifyContent: { xs: "center", md: "flex-start" },
            marginTop: { xs: 4, md: 8 },
          }}
        >
          <motion.button
            whileHover={{
              backgroundColor: secondary,
              color: black,
              scale: 0.95,
            }}
            onClick={scrollToBottom}
            style={{
              borderRadius: 40,
              height: 40,
              background: primary,
              color: white,
              fontWeight: "bold",
              fontFamily: `"Poppins", sans-serif`,
              padding: "0px 20px",
              display: "flex",
              alignItems: "center",
              border: "none",
              cursor: "pointer",
            }}
          >
            LET'S CONNECT
          </motion.button>
        </Grid>
      </Grid>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          height: "10dvh",
          width: "100%",
          background: "rgb(255,255,255)",
          background:
            "linear-gradient(0deg, rgba(255,255,255,1) 1%, rgba(255,255,255,0) 100%)",
          zIndex: 3,
        }}
      ></div>
    </Grid>
  );
};

export default Hero;
