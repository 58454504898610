import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import "firebase/compat/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDVAT8ghkWId5xm3i7A2cn6mudnIL0u1sQ",
  authDomain: "authenticast.firebaseapp.com",
  projectId: "authenticast",
  storageBucket: "authenticast.appspot.com",
  messagingSenderId: "519019502346",
  appId: "1:519019502346:web:6edd53f244c6a07aa4b8f3",
  measurementId: "G-5EMYV7JB68",
};
let app;

if (firebase.apps.length === 0) {
  app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.app();
}

const db = app.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions();

export { db, auth, storage, functions };
export default firebase;
