import { Grid, Button, IconButton } from "@mui/material";
import React from "react";
import { primary, white } from "../global/theme";
import logoWide from "../assets/AAK Logo/5.png";
import { Menu } from "@mui/icons-material";

const AppBar = ({ scrollToSection }) => {
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        height: 60,
        backgroundColor: primary,
        position: "sticky",
        top: "0%",
        left: "0%",
        alignItems: "center",
        zIndex: 100,
      }}
    >
      <Grid
        item
        container
        xs={6}
        style={{ height: "inherit", paddingLeft: "2%" }}
      >
        <img src={logoWide} alt="" style={{ height: "100%" }} />
      </Grid>

      <Grid
        item
        container
        xs={6}
        style={{
          justifyContent: "flex-end",
          alignItems: "center",
          paddingRight: "2%",
        }}
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Button style={{ color: white, fontWeight: "bold" }}>HOME</Button>
        <Button
          onClick={() => scrollToSection("about")}
          style={{ color: white, fontWeight: "bold" }}
        >
          ABOUT
        </Button>
        <Button
          onClick={() => scrollToSection("portfolio")}
          style={{ color: white, fontWeight: "bold" }}
        >
          PORTFOLIO
        </Button>
        <Button
          onClick={() => scrollToSection("connect")}
          style={{ color: white, fontWeight: "bold" }}
        >
          CONNECT
        </Button>
      </Grid>

      <Grid
        item
        container
        xs={6}
        style={{ justifyContent: "flex-end", alignItems: "center" }}
        sx={{ display: { sx: "flex", md: "none" } }}
      >
        <IconButton style={{ color: white }}>
          <Menu />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default AppBar;
